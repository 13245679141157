import React, { useCallback, useEffect, useState } from "react";
import { Col, Row, Alert } from "reactstrap";
import "./AvatarAndPersonalInfo.scss";
import { AccountState } from "logic/src/models";
import { SessionItem } from "./SessionItem";
import Avatar from "../../assets/img/avatar.png";
import moment from "moment";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import StarRatings from "react-star-ratings";
import { Line } from 'rc-progress';
import {emptytStateDataAction, updateTrainerPayoutAction} from 'logic';
import { useDispatch } from 'react-redux';
import Select from 'react-select'

interface IProps {
  userProfile: AccountState;
  dashboard?: any;
  packages?: any;
  getStateDataAction?: any;
  reviewsByTrainerId: any;
}

export const AvatarAndPersonalInfo = (props) => {
  const { userProfile, dashboard, packages, getStateDataAction, reviewsByTrainerId, changeTab } = props;
  const [params, setParams] = useState({});
  const [days, setDays] = useState(0);
  const [hours, setHours] = useState(0);
  const [isExpire, setIsExpire] = useState(false);
  const [finalRating, setFinalRating] = useState(0);
  const [userPackage, setUserPackage] = useState<any>({status:'', package:{package_session_type:''}});
  const [userPackagesArr, setUserPackagesArr] = useState<any[]>();
  const [payoutTrainer, setPayoutTrainer] = useState<boolean>(userProfile.stripe_payout);
  const stats = dashboard && dashboard.stats;
  const trainerStates = dashboard && dashboard.statsTrainer;
  const dispatch = useDispatch();
  const [isOpen, setIsOpen] = useState(false);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };
  // useEffect(() => {
  //   if(reviewsByTrainerId && reviewsByTrainerId.data.length) {
  //     let addRating = 0
  //     reviewsByTrainerId.data.map((review, index) => {
  //       addRating = addRating + review.rating
  //     })
  //     calculateRaing(reviewsByTrainerId.data.length*5, addRating)
  //   }
  // }, [reviewsByTrainerId])

  //


  useEffect(() => {
    if (stats && stats.expireTime) {
      var now = moment(new Date()); //todays date
      var end = moment(stats.expireTime, "YYYY-MM-DD hh:mm:ss A");
      var duration = moment.duration(now.diff(end));
      let currentPackage = userPackage && userPackage;
      
      if (currentPackage) {
        let exDate = moment(
          currentPackage.expire_time,
          "YYYY-MM-DD hh:mm:ss A"
        );
        // check is expired
        if(exDate.isBefore(now)) {
          setIsExpire(true)
        } else {
          setIsExpire(false)
        }
        let days = exDate.diff(now, "days");
        let hours = exDate.subtract(days, "days").diff(now, "hours");
        console.log("This is currentPackage2", currentPackage.expire_time);
        setDays(days);
        setHours(hours);
        setUserPackage(currentPackage);
      } else {
        let descUserPackages = packages && packages.userPackage.sort(
          (x, y) =>
            new Date(y.created_at).getTime() - new Date(x.created_at).getTime()
        );
        if(descUserPackages){
          let mostRecentPackage = descUserPackages[0];
          let exDate = moment(
            mostRecentPackage.expire_time,
            "YYYY-MM-DD hh:mm:ss A"
          );
          let days = exDate.diff(now, "days");
          let hours = exDate.subtract(days, "days").diff(now, "hours");
  
          setDays(days);
          setHours(hours);
          setUserPackage(mostRecentPackage);
        }
      }
    }
  }, [stats]);

  useEffect(() => {
    if (packages && packages.userPackage && packages.userPackage.length > 0) {
      console.log("user packages", packages.userPackage);
      let cpackage = packages.userPackage.find((x) => x.status == "ACTIVE");
      if (userProfile.id) {
        if (cpackage) {
          getStateDataAction(userProfile.id, cpackage.id);
        } else {
          let descUserPackages = packages.userPackage.sort(
            (x, y) =>
              new Date(y.created_at).getTime() -
              new Date(x.created_at).getTime()
          );
          let mostRecentPackage = descUserPackages[0];
          getStateDataAction(userProfile.id, mostRecentPackage.id);
        }
      }
    }else{
      dispatch(emptytStateDataAction());
      setDays(0);
      setHours(0);
      setUserPackage({status:'', package:{package_session_type:''}} 
    );
    }
  }, [packages]);

  useEffect(() => {
    setUserPackagesArr(packages?.userPackage);
    getPackages(packages)
  }, [packages]);

  const getPackages = useCallback(async (packArr)=>{
    if(packArr && packArr.userPackage && packArr.userPackage.length > 0){
      let activeUserPckage = packArr.userPackage.find(x=>x.status=='ACTIVE');
      if(activeUserPckage){
        setUserPackage(activeUserPckage);
      }else{
        let descUserPackages = packArr.userPackage.sort((x,y)=>new Date(y.created_at).getTime() - new Date(x.created_at).getTime())
        let mostRecentPackage = descUserPackages[0];
        setUserPackage(mostRecentPackage)
      }
    }
  },[])

  useEffect(()=>{
    getStats(userPackage?.id)
  },[userPackage])

  const getStats = (userPackageId = 0) => {
    if(userProfile && userProfile.id){
      if(userPackageId){
        getStateDataAction(userProfile.id, userPackageId)
      }
    }
  }

  const calculateRaing = (totalStars=1, gainStars=1) => {
    if(gainStars === 0 || totalStars === 0) {
      if(finalRating !== 5) {
        setFinalRating(5)
      }
    } else {
      let Rating = ((gainStars/totalStars)*5)
      Rating = parseFloat(''+Rating.toFixed(1));
      console.log('Raiong', Rating)
      if(finalRating !== Rating) {
        setFinalRating(Rating)
      }
    }
  }

  const onChangeUserPackageDropdown = (e) => {
    setUserPackage(e)
  }

  useEffect(() => {
    setPayoutTrainer(userProfile?.stripe_payout);
  }, [userProfile])
  

  const onChangePayoutToggle = async() => {
    let {id, stripe_payout} = userProfile;
    if(userProfile && id) {
      let res = await dispatch(updateTrainerPayoutAction(!stripe_payout, id));
      if(res){
        userProfile.stripe_payout = !payoutTrainer;
        setPayoutTrainer(!payoutTrainer)
      }
    }
  }

  let progressArray = [1,2,3,4,5]
  return (
    <Row>
      <Col sm="12">
        <Row>
          <Col className="col-lg-3 col-md-3 col-sm-5 col-xs-6 ">
            <img
              className="profile-img"
              src={userProfile.avatar || Avatar}
              alt={"profile picture"}
            />
          </Col>
          <Col className="col-lg-3 col-md-3 col-sm-5 col-xs-6">
            <div className="overview-personal-info">
              <h4 className="primary-color ">
                {userProfile ? userProfile.firstname : ""}
              </h4>
              {reviewsByTrainerId && reviewsByTrainerId?.rows && reviewsByTrainerId?.rows?.length ? <StarRatings
                rating={finalRating}
                starDimension="15px"
                starSpacing="5px"
                starRatedColor="orange"
                numberOfStars={5}
              /> : null}
              <p>
                <strong> Description:</strong>{" "}
                {userProfile ? userProfile.description : ""}{" "}
              </p>
              <p className="user-data">
                <i className="fa fa-male"></i>
                <strong>Gender: </strong>{" "}
                {userProfile ? userProfile.gender : ""}
              </p>
              <p className="user-data">
                <i className="fa fa-at"></i>
                <strong>Mail: </strong> {userProfile ? userProfile.email : ""}
              </p>
              <p className="user-data">
                <i className="fa fa-globe"></i>
                <strong>Country: </strong>{" "}
                {userProfile
                  ? (userProfile.Country && userProfile.Country.name) || ""
                  : ""}
              </p>
              <p className="user-data">
                <i className="fa fa-map"></i>
                <strong>State: </strong>{" "}
                {userProfile
                  ? (userProfile.State && userProfile.State.name) || ""
                  : ""}
              </p>
              <p className="user-data">
                <i className="fa fa-university"></i>
                <strong>City: </strong>{" "}
                {userProfile
                  ? (userProfile.City && userProfile.City.name) || ""
                  : ""}
              </p>
              {/* <p className="user-data">
                                    <i className="fa fa-circle-o"></i>
                                    <strong>Franchise: </strong> {userProfile ? (userProfile.Franchise && userProfile.Franchise.) : ''}
                                </p> */}
              <p className="user-data">
                <i className="fa fa-map-marker"></i>
                <strong>Zip: </strong> {userProfile ? userProfile.zipcode : ""}
              </p>
              <p className="user-data">
                <i className="fa fa-phone"></i>
                <strong>Phone: </strong> {userProfile ? userProfile.phone : ""}
              </p>
              <p className="user-data">
                <i className="fa fa-money"></i>
                <strong>Stripe Id: </strong> {userProfile ? userProfile.stripe_id : ""}
              </p>
              {/* sign in type */}
                 {
                 userProfile ? 
                 (
                 userProfile.provider == 'google' ? <p className="signin-type google-signin-color">Signed in with Google</p>
                 : userProfile.provider == 'facebook' ? <p className="signin-type facebook-signin-color">Signed in with Facebook</p>
                 : userProfile.provider == 'apple' ? <p className="signin-type apple-signin-color">Signed in with Apple</p>
                 : <p className="signin-type credential-signin-color">Signed in with credentials</p>
                 )
                 :
                 ""}
            </div>
          </Col>
          {userProfile.roles && userProfile.roles[0].slug !== 'customer' && trainerStates && <Col>
          <Row className="justify-content-end">
            <Col md={4} className="d-flex align-items-center justify-content-end">
              <span>Trainer Payout</span>
              <label className="ml-2 mt-2">
                <input className="switch" type="checkbox" checked={payoutTrainer} onChange={()=>{onChangePayoutToggle()}} />
                <div>
                    <div></div>
                </div>
              </label> 
            </Col>
          </Row>
            <div className="trainerStat">
              <div className="trainerStatFirstDiv">
                <div className="sessionStyle" onClick={() => changeTab("5")}>
                  <p className="h4 primary-color">
                    {trainerStates?.sessionCompleted}
                  </p>
                  <p className="h5">Sessions Completed</p>
                </div>
                <div className="retentionStyle">
                <div className="progressStyle">
                    <CircularProgressbar
                      styles={buildStyles({
                        pathColor: "rgba(50,197,210,1)",
                        trailColor: "#d6d6d6",
                      })}
                      value={trainerStates?.retention_rate}
                      text={`${
                        trainerStates?.retention_rate
                          ? trainerStates?.retention_rate
                          : 0
                      }%`}
                    />
                  </div>
                  <br/>
                  <p className="h5">Retention Rate</p>
                </div>
                <div className="sessionStyle" onClick={() => changeTab("6")}>
                  <p className="h4 primary-color">
                    {userProfile?.Country?.curency_symbol}{userProfile?.Country?.curency_symbol === 'AED' ? ' ' : ''}{trainerStates?.totalEarning}
                  </p>
                  <p className="h5">Total Earnings</p>
                </div>
              </div>
            {reviewsByTrainerId && reviewsByTrainerId.rows && reviewsByTrainerId.rows.length ? 
            <div style={{display:'flex',flexDirection:'row', justifyContent:'center', alignItems:'center', marginTop:20}}>
                <div style={{ cursor: 'pointer' }} onClick={() => changeTab("9")}>
                    <h1 >{finalRating}</h1>
                    <p>Out of 5</p>
                </div>
                <div  style={{display:'flex', flexDirection:'column-reverse', alignItems:'flex-end', cursor: 'pointer'}} onClick={() => changeTab("9")}>
                    <p>{reviewsByTrainerId?.starRating?.totalreviews} Reviews</p>
                {Object.entries(reviewsByTrainerId?.starRating).length && Object.entries(reviewsByTrainerId?.starRating).map(([key, value]: any, index) => {
                    if(index === 4) {
                        calculateRaing(reviewsByTrainerId?.starRating?.totalreviews*5, reviewsByTrainerId?.starRating?.ratingsum)
                    }
                    let DefratingProgress = (parseInt(''+value)/parseInt(''+reviewsByTrainerId?.starRating?.totalreviews))*100
                    
                    if (index <= 4) {
                      return(
                      <div style={{display:'flex', flexDirection:'row', justifyContent:'flex-end', alignItems:'center'}}>
                          <StarRatings 
                              rating={index+1}
                              starDimension="15px"
                              starSpacing="5px"
                              starRatedColor="orange"
                              numberOfStars={index+1}
                          />
                          <Line
                              percent={DefratingProgress || 0}
                              strokeWidth={4}
                              trailWidth={4}
                              strokeColor="#32c5d2"
                              style={{width:100, marginLeft:5}}
                              strokeLinecap={'butt'}
                          />
                      </div>
                      )
                    }
                })}
                </div>
            </div> : null}
            </div>
          </Col>}
          {(userProfile.roles && userProfile.roles[0].slug === 'customer' && stats && (
            <Col className="col-lg-4 col-md-4 col-sm-5 col-xs-6 ">
              <div className="overview-personal-info">
                <h4 className="primary-color ">Package Summary</h4>
                <div className="package-dropdown w-100">
                  <button onClick={toggleDropdown} className="package-dropdown-toggle w-100 d-flex justify-content-between">
                    <span>{userPackage?.package?.package_name}</span>
                    <svg height="20" width="20" viewBox="0 0 20 20" aria-hidden="true" focusable="false" ><path d="M4.516 7.548c0.436-0.446 1.043-0.481 1.576 0l3.908 3.747 3.908-3.747c0.533-0.481 1.141-0.446 1.574 0 0.436 0.445 0.408 1.197 0 1.615-0.406 0.418-4.695 4.502-4.695 4.502-0.217 0.223-0.502 0.335-0.787 0.335s-0.57-0.112-0.789-0.335c0 0-4.287-4.084-4.695-4.502s-0.436-1.17 0-1.615z"></path></svg>
                  </button>
                  {isOpen && (
                    <div className="package-dropdown-content border">
                      {
                      userPackagesArr?.map((x, index)=>{
                        return <div role="button" style={{backgroundColor: x.id === userPackage?.id ? "#f1f1f1" : ""}} className={"package-dropdown-item  "+ (index!==0?"border-top":"")} onClick={()=> {
                          onChangeUserPackageDropdown(x);
                          setIsOpen(false);
                        }}>
                        <div className="d-flex justify-content-between align-items-center" >
                          <span>{x.package.package_name}</span>
                        </div>
                        <div className="d-flex justify-content-between align-items-center">
                          <span className="package-purchase-date">Purchased on</span>
                          <span className="package-purchase-date">{moment(x.created_at).format("MMM DD YYYY")}</span>
                        </div>
                     </div>
                      })
                      }
                     
                    </div>
                  )}
                </div>
                
               {userPackage && userPackage?.status == "DEACTIVE" ? (
                  <Alert color="danger">
                    This Package has been deactivated.
                  </Alert>
                ) : userPackage &&
                  userPackage?.status == "ACTIVE" &&
                  isExpire ? (
                  <Alert color="danger">This Package has expired.</Alert>
                ) : userPackage &&
                  userPackage?.status == "ACTIVE" &&
                  !isExpire ? (
                  <Alert color="success">
                    Session expire in {days} days {hours} hours.
                  </Alert>
                ) : (
                  ""
                )}

                <Row>
                  <Col>
                    <SessionItem
                      amount={stats?.paidSessions}
                      label="Paid Sessions"
                    />
                  </Col>
                  <Col>
                    <SessionItem
                      amount={stats?.completedSessions}
                      label="Completed Sessions"
                      isPrimaryColor={true}
                    />
                  </Col>
                  <Col>
                    <SessionItem
                      amount={stats?.remainingPaidSessions}
                      label="Remaining Paid Sessions"
                    />
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <SessionItem
                      amount={userPackage && userPackage?.package?.package_session_type == 'session' ? 'N/A' : stats?.sessionsPerMonth}
                      label="Sessions Per Month"
                    />
                  </Col>
                  <Col>
                    <SessionItem
                      amount={stats && stats?.totalMonths}
                      label="Total Months"
                      isPrimaryColor={true}
                    />
                  </Col>
                  <Col>
                    <SessionItem
                      amount={stats && stats?.totalCommitedSessions}
                      label="Total Committed Sessions"
                    />
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <SessionItem
                      amount={stats && stats?.pricePerSession}
                      prefix={
                        userProfile && userProfile?.Country
                          ? userProfile?.Country?.curency_symbol
                          : "$"
                      }
                      label={"Price Per Session"}
                    />
                  </Col>
                  <Col>
                    <SessionItem
                      amount={
                        stats && stats?.payableAmount ? stats?.payableAmount : 0
                      }
                      prefix={
                        userProfile && userProfile?.Country
                          ? userProfile?.Country?.curency_symbol
                          : "$"
                      }
                      label={stats?.paymentType}
                    />
                  </Col>
                </Row> 
              </div>
            </Col>
          )) ||
            null}
        </Row>
      </Col>
    </Row>
  );
};
