import React, { Component } from "react";
import { Col, Row, Container } from "reactstrap";
import "./SessionItem.scss";
interface IProps {
  amount: number;
  prefix: string;
  label: string;
  isPrimaryColor: boolean;
}

class SessionItem extends Component<IProps> {
  public static defaultProps = {
    prefix: "",
    isPrimaryColor: false,
  };
  render() {
    const { amount = 0, prefix, label, isPrimaryColor } = this.props;
    return (
      <Container className="session-item">
        <Row className="amount-row">
          {isPrimaryColor && (
            <div className="amount-info primary-color">
              {prefix} {amount}
            </div>
          )}
          {!isPrimaryColor && (
            <div className="amount-info">
              {prefix} {amount}
            </div>
          )}
        </Row>
        <Row className="label-row">{label}</Row>
      </Container>
    );
  }
}

export { SessionItem };
