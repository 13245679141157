import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { isAuthenticated } from '../../utilities';

const PrivateRoute = ({component: Component, ...rest}) => {
    return(
        <Route
            {...rest}
            render={ props =>
                isAuthenticated() ? (
                    <Component {...props} isAdmin={rest.isAdmin} />
                ):(
                    <Redirect
                        to={{
                        pathname: "/login",
                        state: { from: props.location }
                        }}
                    />
                )
            }
        />
    )
}

export { PrivateRoute }
