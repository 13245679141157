import React from "react";
import {FormFeedback, FormGroup, Input, InputGroup, InputGroupAddon, InputGroupText, Label} from "reactstrap";
import { InputType } from "reactstrap/es/Input";

interface IProps {
    className?: string;
    icon?: string;
    type?: string;
    placeholder: string;
    name: string;
    value: any;
    invalid?: boolean;
    onChange: (evt) => void;
    required?: boolean;
    textInvalid?: string;
    label?: string;
    show?: boolean; 
    min?: number;
    max?: number;
    disabled?: boolean
    rows?:number
    style?:any;
}

const TextInput = (props: IProps) => {
    const {
        className = "",
        icon,
        placeholder,
        name,
        value,
        invalid = false,
        onChange,
        required = false,
        textInvalid = "",
        label='',
        show=true,
        min=0,
        disabled = false,
        style={}
        // max=0
    } = props;
    if(!show) return null
    return (
        <div className={className}>
            <FormGroup>
                {label && (<Label for={name} className={'mt-2'}>{label}</Label>)}
                <InputGroup>
                    {icon && (
                        <InputGroupAddon addonType="prepend">

                            <InputGroupText style={{width:'100%'}}>
                                {icon == 'fa-aed' 
                                ? <p style={{ padding:0, margin:0, color:'black', fontSize:'90%' }}>AED</p>
                                :<i className={icon}> {icon == '$' && icon}</i>
                                }
                            </InputGroupText>
                        </InputGroupAddon>
                    )}
                    <Input
                        type={props.type as InputType}
                        placeholder={placeholder}
                        autoComplete={name}
                        name={name}
                        required={required}
                        min={min}
                        rows={props.rows}
                        // max={max}
                        value={value || ""}
                        invalid={invalid}
                        onChange={onChange}
                        disabled={disabled}
                        style={style}
                    />
                    {required && <FormFeedback>{textInvalid}</FormFeedback>}
                </InputGroup>
            </FormGroup>
        </div>
    );
};

export { TextInput };
